/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var State = History.getState();
var manual_state_change = false;
var mobile = false;
var iOS = false;
var hfAutoApply_ran = false;

if(State.hash && State.hash.indexOf("careers") > -1){
    var search = location.search.substring(1);
    if(search !== undefined && search.length > 0){
        var params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
        var load_kw = params.search; // category
        var load_ct = 'tab-'+params.tab; // category
        var load_id = params.id; // selected job ID
        var load_jt = params.job_title; // selected job ID
    }
}

var loading = true;

(function($){



    $(window).scroll(function() {
        var scroll = $(window).scrollTop();

        //>=, not <=
        if (scroll >= 40) {
            //clearHeader, not clearheader - caps H
            $("#header").addClass("scrolled-header");
        }

        if (scroll < 40) {
            $("#header").removeClass("scrolled-header");
        }

            }); //missing );

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function(){
                // gravity form hooks
                gform_hooks();
                $(document).bind('gform_post_render', function(){
                    initCustomForms();
                    gform_hooks();
                });

                // login form
                $('.login-form input').focusout(function(){
                    if($(this).attr('value') !== ''){
                        $(this).addClass('has-text');
                    }else{
                        $(this).removeClass('has-text');
                    }
                });

                $('.login-form').on('click', '.btn.submit', function(){
                    $('.login-spinner').css('display', 'inline-block');
                });

                // referral cookie
                var hf_referred_by = Cookies.get('hf_referred_by');
                if(hf_referred_by === undefined){
                    Cookies.set('hf_referred_by', document.referrer, {expires: 30});
                }

                // Window resize events
                is_mobile();
                var resizeId;
                $(window).resize(function() {
                    clearTimeout(resizeId);
                    resizeId = setTimeout(doneResizing, 500);
                });
                function doneResizing(){
                    is_mobile();
                    positionJob(false);
                }

                // userAgent
                iOS = is_iOS();

                // notification close
                $('a.close').click(function(e){
                    e.preventDefault();
                    $(this).parent().remove();
                });
            },
            finalize: function(){
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function(){
                // JavaScript to be fired on the home page
            },
            finalize: function(){
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // Company page
        'company': {
            init: function(){
                $('.items-area .item > a').hoverIntent(function(){
                    if(!is_mobile()){
                        $(this).find('.onhover').css('opacity', '1');
                    }
                }, function(){
                    if(!is_mobile()){
                        $(this).find('.onhover').css('opacity', '0');
                    }
                });

                // workaround to fix arrow and close buttons to top on mobile
                $(document).on('scroll', '.mfp-wrap', function() {
                    console.log('scrolled');
                    clearTimeout($.data(this, 'scrollTimer'));
                    $.data(this, 'scrollTimer', setTimeout(function() {
                        if(is_mobile()){
                            var mfp_scrolled = $('.mfp-wrap').scrollTop();
                            console.log(mfp_scrolled);
                            $('.mfp-arrow-left, .mfp-arrow-right, .mfp-close').css('top', mfp_scrolled+"px");
                        }
                    }, 250));
                });
            },
            finalize: function(){
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // Careers (job search) page
        'careers': {
            init: function(){
                History.Adapter.bind(window, 'statechange', function(){
                    if(manual_state_change === true){
                        manual_state_change = false;
                        return;
                    }
                    var stateKW = '';
                    var stateCT = '';
                    var stateID = '';
                    if(State.hash){
                        var search = location.search.substring(1);
                        if(search !== undefined && search.length > 0){
                            var params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
                            var ct = params.ct; // category
                            var id = params.id; // selected job ID
                        }
                    }
                });

                var timer = setInterval(function() {
                    if($('#sidebar .tab-content > div').hasClass('js-tab-hidden')){
                        //run some other function
                        pageIsLoaded();
                        clearInterval(timer);
                    }
                }, 200);

                // moved the following to headfarmer.js where the other tab-switching code already lived
                //$('.sidebar-tabset li a').click(function(){
                    //console.log('----State needs to changed');
                    //manual_state_change = true;
                    //gatherState();
                //});

                // fill out special form fields initially
                var current_url = window.location.href;
                if($('#input_2_11').length){
                    $('#input_2_11').attr('value', current_url);
                }
                if($('#login_from_url').length){
                    $('#login_from_url').attr('value', current_url);
                    $('.flp_form input[name="redirect_to"]').attr('value', current_url+'&apply=true');
                }

                // view a job
                $('.job-list .item-list li > a').click(function(e){
                    e.preventDefault();

                    if(is_mobile()){
                        if($(this).parent().hasClass('active')){
                            $('.job-list .item-list li').css('height', "auto");
                            $("#content").css('visibility', 'hidden');
                            $("#content").html('');
                            $('.job-list .item-list li').removeClass('active');
                            $('body').attr('style', 'min-height: auto');
                            return null;
                        }
                    }

                    $('.job-list .item-list li').removeClass('active');
                    $(this).parent().addClass('active');
                    $(this).parent().addClass('visited');
                    manual_state_change = true;
                    loadJob();
                    gatherState();
                });

                // apply for a job
                $('#content').on('click', '.apply-now', function(e){
                    e.preventDefault();
                    if($(this).attr('disabled') == 'disabled'){
                        return null;
                    }
                    //$('.btn.apply-now').attr('disabled', 'disabled').text('APPLYING...');
                    $('.btn.apply-now').attr('disabled', 'disabled').after('<img class="apply-spinner" src="/wp-content/themes/headfarmer/dist/images/load.gif">');
                    var job_id = $('.job-list .item-list li.active a').attr('data-id');
                    var job_title = $('.job-list .item-list li.active a strong').html();
                    var apply_time = new Date();
                    var data = {
                        'action': 'apply_for_job',
                        'job_id': job_id,
                        'job_title': job_title,
                        'apply_time': hfFormatDate(apply_time)
                    };

                    jQuery.post(ajax_object.ajax_url, data, function(response) {
                        if(response == 'error'){
                            alert('There was a problem!');
                            $('.btn.apply-now').attr('disabled', 'disabled').text('APPLY NOW');
                            $('.apply-spinner').remove();
                        }else{
                            $("#content .content-holder").prepend('<div class="have-applied">' + response + ' <img height="1" width="1" border="0" src="//conv.indeed.com/pagead/conv/9819282325193108/?script=0"></div>');
                            $('.job-list .item-list li > a[data-id="'+data.job_id+'"] strong').prepend('&check; ');
                            $('.apply-now').remove();
                            $('.apply-spinner').remove();
                            initSameHeight();
                            initMagnificPopup();
                            initCustomForms();
                        }
                    });
                });

                // click on register link, either from apply button or on its own
                $('#content').on('click', '.prereg-apply-now', function(e){
                    e.preventDefault();
                    if(jQuery('#input_2_13').length){
                        jQuery('#input_2_13').attr('value', 'prereg-apply-now');
                    }
                    if(jQuery('#login_action').length){
                        jQuery('#login_action').attr('value', 'login-apply-now');
                    }
                });
                $('#footer').on('click', '.prereg-no-apply', function(e){
                    e.preventDefault();
                    if(jQuery('#input_2_13').length){
                        jQuery('#input_2_13').attr('value', '');
                    }
                    if(jQuery('#login_action').length){
                        jQuery('#login_action').attr('value', '');
                    }
                });

                // job share links
                $('#content').on('click', '.share-link', function(e){
                    e.preventDefault();
                    var service = $(this).attr('data-service');
                    var encode_url = encodeURIComponent(window.location.href);
                    if(service == 'email'){
                        var share_link = "mailto:?subject=Check out this job from headfarmer.com&body="+encode_url;
                        console.log(share_link);
                        window.location.href = share_link;
                        return true;
                    }
                    switch(service){
                        case 'facebook':
                            var share_link = "https://www.facebook.com/sharer/sharer.php?u="+encode_url;
                            break;
                        case 'twitter':
                            var share_link = "https://twitter.com/home?status="+encode_url;
                            break;
                        case 'linkedin':
                            var share_link = "https://www.linkedin.com/shareArticle?mini=true&url="+encode_url;
                            break;
                        default:
                            return null;
                    }
                    window.open(share_link, "pop", "width=600, height=400");
                });
            },
            finalize: function(){
                // JavaScript to be fired on the home page, after the init JS
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args){
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if(fire){
                namespace[func][funcname](args);
            }
        },
        loadEvents: function(){
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm){
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);


    /**
     * Custom functions
     */

    function loadJob(){
        if(mobile){
            $('.job-list .item-list li').css('height', "auto");
            $("#content").css('visibility', 'hidden');

            var activePos = $('.job-list .item-list li.active').position();
            var body = $("html, body");
            body.stop().animate({scrollTop:activePos.top}, '500', 'swing');
        }else{
            $("#content").html('');
            $('.image-head').addClass('loading');
        }
        $('#content, #sidebar').removeClass('no-job-selected');
        var job_id = $('.job-list .item-list li.active a').attr('data-id');
        var data = {
            'action': 'load_single_job',
            'job_id': job_id
        };

        jQuery.post(ajax_object.ajax_url, data, function(response) {
            $('.image-head').removeClass('loading');
            $("#content").html(response);
            $('#main').removeClass('no-results');
            $('#main').addClass('with-results');
            initSameHeight();
            initMagnificPopup();
            initCustomForms();
            positionJob();
            if(typeof hfAutoApply == 'function'){
                if(!hfAutoApply_ran){
                    hfAutoApply();
                    hfAutoApply_ran = true;
                }
            }
        });
    }

    function sLoad(){
        $('.load').show();
    }

    function hLoad(){
        $('.load').hide();
    }

})(jQuery); // Fully reference jQuery after this point.



function pageIsLoaded(){
    if(loading === true){
        jQuery('.sidebar-tabset li a[href="#'+load_ct+'"]').trigger('click');
        if(load_id !== 'undefined'){
            jQuery('.job-list .item-list li > a[data-id="'+load_id+'"]').trigger('click');
        }
        if(typeof hfClickTab == 'function'){
            hfClickTab();
        }
        loading = false;
    }
}

function gatherState(){
    if(loading === true){
        return null;
    }

    // category tab
    var ct_string = jQuery('.sidebar-tabset li.active a').attr('href').replace('#', '').replace('-', '=');
    jQuery("input#tab").attr('value', ct_string.replace('tab=', ''));

    // keyword search
    var kw_val = jQuery('.search-form #search').val();
    if(kw_val === undefined){
        var kw_string = '';
    }else{
        var kw_string = '&search=' + kw_val;
    }

    // currently selected job
    var id_val = jQuery('.job-list .item-list li.active a').attr('data-id');
    if(id_val === undefined){
        var id_string = '';
    }else{
        var id_string = '&id=' + id_val;
    }
    var jt_val = jQuery('.job-list .item-list li.active a').attr('data-slug');
    if(jt_val === undefined){
        var jt_string = '';
    }else{
        var jt_string = '&job_title=' + jt_val;
    }

    // push new state
    History.pushState(
        {
            ct: ct_string,
            id: id_string
        },
        document.title,
        '?' + ct_string + kw_string  + id_string + jt_string
    );

    var current_url = window.location.href;
    var current_url_encoded = encodeURIComponent(window.location.href);
    if(jQuery('#input_2_11').length){
        jQuery('#input_2_11').attr('value', current_url);
    }
    if(jQuery('#login_from_url').length){
        jQuery('#login_from_url').attr('value', current_url);
        jQuery('.flp_form input[name="redirect_to"]').attr('value', current_url+'&apply=true');
    }
}

function positionJob(scrollToJob){
    scrollToJob = typeof scrollToJob !== 'undefined' ? scrollToJob : true;

    if(!jQuery('.job-list').length){
        return false;
    }
    if(mobile){
        jQuery('.job-list .item-list li').css('height', "auto");
        var itemHeight = jQuery(".job-list .item-list li.active").outerHeight();
        var contentHeight = jQuery("#content").outerHeight();
        jQuery('.job-list .item-list li.active').css('height', (itemHeight + contentHeight) + "px");

        var activePos = jQuery('.job-list .item-list li.active').position();
        var contentTop = activePos.top + jQuery('.job-list .item-list li.active a').outerHeight();
        var contentCSS = '{"position":"absolute","top":"' + contentTop + 'px","visibility":"visible"}';
        contentCSS = JSON.parse(contentCSS);
        jQuery("#content").css(contentCSS);

        var bodyMinHeight = jQuery('#sidebar').outerHeight();
        jQuery('body').attr('style', 'min-height: ' + bodyMinHeight + 'px');

        if(scrollToJob){
            console.log('scrolling automatically');
            var body = jQuery("html, body");
            body.stop().animate({scrollTop: activePos.top}, '500', 'swing');
        }
    }else{
        jQuery('.job-list .item-list li').css('height', "auto");
        var contentCSS = '{"position":"relative","top":"auto"}';
        contentCSS = JSON.parse(contentCSS);
        jQuery("#content").css(contentCSS);
    }
}

function gform_hooks(){
    jQuery('.register-form .ginput_container > input').focus(function(){
        jQuery(this).parent().parent().find('label').addClass('focused');
    });
    jQuery('.register-form .ginput_container > input').focusout(function(){
        jQuery(this).parent().parent().find('label').removeClass('focused');
    });
    jQuery('.register-form .ginput_container input').focusout(function(){
        if(jQuery(this).attr('value') !== ''){
            jQuery(this).addClass('has-text');
        }else{
            jQuery(this).removeClass('has-text');
        }
    });
    jQuery('.register-form .gfield_contains_required input').attr("required", "required");
    jQuery('.register-form .ginput_container input').trigger('blur');
}

function is_mobile(){
    if(jQuery('#footer').css('text-align') === 'center'){
        mobile = true;
        return true;
    }else{
        mobile = false;
        return false;
    }
}

function is_iOS(){
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if(userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)){
        jQuery('html').attr('class', 'ios');
        return true;
    }else{
        return false;
    }
}

function hfFormatDate(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear() + " at " + strTime;
}